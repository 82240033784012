























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
// @ts-ignore
import { VueGoodTable } from "vue-good-table";
import { Bill, BillViewModel } from "@/models";
import ModalNoticeEdit from "@/components/Modal/ModalNoticeEdit.vue";

@Component({ components: { VueGoodTable, ModalNoticeEdit } })
export default class ListBill extends Vue {
  @Prop({ default: () => [] }) bills!: Bill[];
  @Prop() isCreateBill!: boolean;
  @Prop() updateBills!: boolean;
  private selectedIndex: number = -1;
  private selectedIndexTemp: number = -1;
  private selectedRow: BillViewModel = new BillViewModel();

  private columns: any[] = [
    {
      label: "#",
      field: "id",
      hidden: true
    },
    {
      label: "Số bill",
      field: "billNo"
    },
    {
      label: "Ngày INV",
      field: "billDate",
      sortFn: this.onSortDate
    },
    {
      label: "Số invoice",
      field: "invoiceNo"
    },
    {
      label: "Hãng tàu",
      field: "carrier"
    },
    {
      label: "Mã tàu",
      field: "shipCode"
    },
    {
      label: "DP",
      field: "dp"
    },
    {
      label: "ETD",
      field: "etd",
      sortFn: this.onSortDate
    },
    {
      label: "TP",
      field: "ap"
    },
    {
      label: "ETP",
      field: "eta",
      sortFn: this.onSortDate
    },
    {
      label: "FP",
      field: "fp"
    },
    {
      label: "ETA",
      field: "etf",
      sortFn: this.onSortDate
    },
    {
      label: "",
      field: "action",
      width: "30px",
      sortable: false
    }
  ];
  private rows: BillViewModel[] = [];

  private searchOptions: any = {
    enabled: true,
    skipDiacritics: true,
    placeholder: "nhập gì đó..."
  };

  private paginationOptions: any = {
    enabled: true,

    perPage: 5,
    position: "bottom",
    perPageDropdownEnabled: false,
    jumpFirstOrLast: false,
    nextLabel: this.$t("common.table.next"),
    prevLabel: this.$t("common.table.prev")
  };

  created() {
    this.setRow();
    this.$bus.$on("confirmedChange", () => {
      this.selectedIndex = this.selectedIndexTemp;
      this.confirmedChange();
    });
  }

  destroyed() {
    this.$bus.$off("confirmedChange");
  }

  @Watch("bills")
  @Watch("updateBills")
  setRow() {
    this.rows = this.bills.map(item => new BillViewModel(item));
    this.chosseBillByQuery();
  }

  @Watch("isCreateBill")
  watchCreateBill() {
    if (this.isCreateBill) {
      this.selectedIndex = -1;
      this.selectedIndexTemp = -1;
    }
  }

  chosseBillByQuery() {
    if (this.$route.query.billId) {
      const index = this.rows.findIndex(
        item => item.id === Number(this.$route.query.billId)
      );
      const row = this.rows.find(
        item => item.id === Number(this.$route.query.billId)
      );
      this.selectedIndex = index;
      if (row) {
        setTimeout(() => {
          this.selectedRow = row;
          this.confirmedChange();
          this.$router.replace({ query: {} }).catch(() => {});
        }, 100);
      }
    }
  }

  rowStyleClassFn(row: any) {
    if (row.originalIndex === this.selectedIndex) return "bg-bisque fwb";
  }

  onRowClick(params: any) {
    if (this.selectedIndex !== -1) {
      this.selectedIndexTemp = params.row.originalIndex;
      this.selectedRow = params.row;
      this.$bus.$emit("changeBill");
    } else {
      this.selectedIndex = params.row.originalIndex;
      this.selectedRow = params.row;
      this.confirmedChange();
    }
  }

  confirmedChange() {
    const toDate = (str: any) => this.$moment(str, "DD MMM YY").toDate();
    const updateBill = new Bill(this.selectedRow);
    updateBill.billDate = toDate(updateBill.billDate);
    updateBill.etd = toDate(updateBill.etd);
    updateBill.eta = toDate(updateBill.eta);
    updateBill.etf = toDate(updateBill.etf);
    updateBill.vetDate = toDate(updateBill.vetDate);
    updateBill.clearDate = toDate(updateBill.clearDate);
    this.$emit("selectBill", updateBill);
  }

  deleteBill(row: any) {
    this.$swal({
      icon: "question",
      title: `Xóa bill ${row.billNo}?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Xóa",
      cancelButtonText: "Hủy"
    }).then(result => {
      if (result.isConfirmed) {
        this.$swal.fire({
          icon: "success",
          title: "Đã xóa",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });
        this.$emit("deleteBill", row.id);
        if (row.originalIndex === this.selectedIndex) {
          this.$emit("deleteSelectedBill");
        }
      }
    });
  }

  onSortDate(x: any, y: any, col: any, rowX: any, rowY: any) {
    let xVal = this.$moment(x, '"DD MMM YY"');
    let yVal = this.$moment(y, '"DD MMM YY"');
    if (!xVal.isValid() && !yVal.isValid()) return 0;
    if (!xVal.isValid()) return -1;
    if (!yVal.isValid()) return 1;
    return xVal.isBefore(yVal) ? -1 : !xVal.isBefore(yVal) ? 1 : 0;
  }
}
