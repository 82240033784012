





























































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ProductList from "@/components/ProductList.vue";
import {
  Bill,
  Container,
  IdName,
  ModalAddType,
  NewContract,
  Product
} from "@/models";
import CommonService from "@/services/CommonService";
//@ts-ignore
import { VueAutosuggest } from "vue-autosuggest";
import CurrencyInput from "@/components/CurrencyInput.vue";

@Component({ components: { ProductList, VueAutosuggest, CurrencyInput } })
export default class ContainerTab extends Vue {
  @Prop() private isVertical!: boolean;
  @Prop() private startScreen!: boolean;
  @Prop() private contract!: NewContract;
  @Prop() private billId!: number | string;
  @Prop({ default: [] }) private bills!: Bill[];
  private consBills: Bill[] = [];
  private containers: Container[] = [];
  private isEdit: boolean = false;
  private tabIndex: number = 0;
  private count: number = 1;

  ///// PRODUCT /////
  private listProductApi: IdName[] = [];
  private currencyOption: any = {
    locale: "en-US",
    currency: "USD",
    currencyDisplay: "hidden",
    precision: 3,
    valueRange: {
      min: 0
    },
    hideGroupingSeparatorOnFocus: false
  };

  private productSuggestions: any[] = [];
  private eModalAddType = ModalAddType;

  created() {
    this.bindingBills();
    this.updateBill();
    this.getListProduct();
    this.$bus.$on("addProuct", () => this.getListProduct());
  }

  destroyed() {
    this.$bus.$off("createBill");
    this.$bus.$off("updateBill");
    this.$bus.$off("deleteBill");
    this.$bus.$off("refreshBill");
    this.$bus.$off("changeBill");
    this.$bus.$off("saveBill");
    this.$bus.$off("addProuct");
  }

  @Watch("startScreen")
  bindingBills() {
    if (!this.startScreen) {
      this.consBills = this.$lodash.cloneDeep(this.bills);
    }
  }

  @Watch("billId")
  watchBillId() {
    this.containers = [];
    const findBill = this.consBills.find(cons => cons.id === this.billId);
    if (findBill) {
      setTimeout(() => (this.containers = findBill.containers), 1);
    }
  }

  handleContainerName(index: any) {
    if (!this.containers[index].code.trim()) {
      this.containers[index].code = "Container";
    }
  }

  updateBill() {
    this.$bus.$on("createBill", (bill: Bill) => {
      this.consBills.push(new Bill(bill));
    });
    this.$bus.$on("updateBill", (bill: Bill) => {
      const index = this.consBills.findIndex(b => b.id === bill.id);
      const currentCons = this.consBills[index]?.containers;
      this.consBills[index] = new Bill(bill);
      this.consBills[index].containers = currentCons;
    });
    this.$bus.$on("deleteBill", (billId: number | string) => {
      this.consBills = this.consBills.filter(item => item.id !== billId);
    });
    this.$bus.$on("refreshClick", () => {
      this.validateAllProduct().then(valid => {
        if (valid) {
          this.$bus.$emit("refreshForm");
        }
      });
    });
    this.$bus.$on("changeBill", () => {
      this.validateAllProduct().then(valid => {
        if (valid) {
          this.$bus.$emit("confirmedChange");
        }
      });
    });
    this.$bus.$on("saveBill", () => {
      this.validateAllProduct().then(valid => {
        if (valid) {
          this.$bus.$emit("confirmedSave");
        }
      });
    });
  }

  async newContainer() {
    this.count++;
    let newCons = new Container({ code: `Container${this.count}` });

    // if (this.bills.length <= 1 && this.containers.length === 0) {
    newCons.containerProducts = this.contract.contractProductList.map(
      (prod: Product) => {
        return new Product({
          contractId: prod.contractId,
          productId: prod.productId,
          productName: prod.productName,
          value: prod.value
          // price: prod.price
        });
      }
    );
    // }

    this.containers.push(newCons);
    setTimeout(() => {
      this.tabIndex = this.containers.length - 1;
    }, 1);
  }

  deleteContainer() {
    if (this.containers.length < 1) return;
    this.$swal({
      icon: "question",
      title: `Xóa ${this.containers[this.tabIndex].code}?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Xóa",
      cancelButtonText: "Hủy"
    }).then(result => {
      if (result.isConfirmed) {
        this.$swal.fire({
          icon: "success",
          title: "Đã xóa",
          position: "bottom-end",
          toast: true,
          showConfirmButton: false,
          timer: 1500
        });
        const isTabEnd = this.tabIndex === this.containers.length - 1;
        this.containers = this.containers.filter(
          (item: Container, index: number) => index !== this.tabIndex
        );
        isTabEnd ? this.tabIndex === this.containers.length - 1 : "";

        //
        const index = this.consBills.findIndex(cons => cons.id === this.billId);
        this.consBills[index].containers = this.containers;

        this.$emit("rematchTotal");
      }
    });
  }

  openEditCode() {
    this.isEdit = true;
    const ctnRef = `containerCode${this.tabIndex}`;
    setTimeout(() => {
      const ref = (this.$refs[ctnRef] as any)[0];
      ref.focus();
      ref.select();
    }, 1);
  }

  async validateAllProduct() {
    const list: any = this.$refs.productValidate;
    const valids: boolean[] = [];
    const setConsInvalid = new Set();
    this.containers.forEach(item => (item.invalid = false));
    if (!list) return true;
    await Promise.all(
      list.map(async (productRow: any) => {
        await productRow?.validate();
        const valid = productRow?.flags.valid;
        if (!valid) {
          setConsInvalid.add(productRow.$attrs.id);
        }
        valids.push(valid);
      })
    );

    const vm = this;
    function swet(v1: any, v2: any, set: any) {
      vm.containers[Number(v1)].invalid = true;
    }

    setConsInvalid.forEach(swet);
    return valids.every(item => item);
  }

  /**
   * call API get list supplier
   */
  async getListProduct() {
    return CommonService.getAllProuct()
      .then(res => {
        if (res.status === 200) {
          this.listProductApi = res.data.map(
            (item: any) => new IdName({ id: item.id, name: item.name })
          );
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  renderSuggestion(suggestion: any) {
    return suggestion.item.name;
  }

  getSuggestionValue(suggestion: any, prodIndex: number, consIndex: number) {
    this.containers[consIndex].containerProducts[prodIndex].productName =
      suggestion.item.name;
    const prod = this.listProductApi.find(
      item => item.name === suggestion.item.name
    );
    prod
      ? (this.containers[consIndex].containerProducts[prodIndex].productId =
          prod.id)
      : "";
    return suggestion.item.name;
  }

  fetchResults(prodIndex: number, consIndex: number) {
    const filter = this.filterResults(prodIndex, consIndex);
    this.productSuggestions = [];
    filter.length && this.productSuggestions.push({ data: filter });
  }

  filterResults(prodIndex: number, consIndex: number) {
    return this.listProductApi
      .filter((item: any) => {
        if (
          item["name"]
            .toLowerCase()
            .indexOf(
              this.containers[consIndex].containerProducts[
                prodIndex
              ].productName.toLowerCase()
            ) > -1
        ) {
          return item["name"];
        }
      })
      .sort();
  }

  triggerInputChange(prodIndex: number, consIndex: number) {
    const product = this.$refs[`productName-${prodIndex}-${consIndex}`] as any;
    product[0].reset();
    product[0].syncValue(
      this.containers[consIndex].containerProducts[prodIndex].productName
    );
    this.validateAllProduct();
  }

  onChangeTotal() {
    // this.validateAllProduct();
    this.$emit("rematchTotal");
  }

  addNewRow(consIndex: number) {
    this.containers[consIndex].containerProducts.push(new Product());
    this.$emit("addRow");
  }

  deleteRow(prodIndex: number, consIndex: number) {
    this.containers[consIndex].containerProducts = this.containers[
      consIndex
    ].containerProducts.filter(
      (item: Product, index: number) => index !== prodIndex
    );
    this.$emit("rematchTotal");
  }
}
