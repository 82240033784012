

























































































































































































































































































































































































































































































































































































































import DatePickerLang from "@/helpers/DatePickerLang";
import { Bill, IdNameCode, ModalAddType } from "@/models";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
//@ts-ignore
import { VueAutosuggest } from "vue-autosuggest";
import CommonService from "@/services/CommonService";

@Component({ components: { VueAutosuggest } })
export default class FormBill extends Vue {
  @Prop() private bill!: Bill;
  @Prop() private isCreateBill!: boolean;
  private newBill: Bill = new Bill();
  private datePickProp: DatePickerLang = new DatePickerLang();
  private count: number = 0;
  private eModalAddType = ModalAddType;

  //select
  private eAutocomplate: any = EAutocomplate;
  private carrier: IdNameCode[] = [];
  private port: IdNameCode[] = [];

  private carrierSuggestions: any[] = [];
  private dpSuggestions: any[] = [];
  private apSuggestions: any[] = [];
  private fpSuggestions: any[] = [];

  private carrierQuery: string = "";
  private dpQuery: string = "";
  private apQuery: string = "";
  private fpQuery: string = "";

  private carrierString: string = "";
  private portString: string = "";

  created() {
    this.getCarriers();
    this.getPorts();
    this.$bus.$on("refreshForm", () => {
      this.refresh();
    });
    this.$bus.$on("addCarrier", () => this.getCarriers());
    this.$bus.$on("addPort", () => this.getPorts());
  }

  destroyed() {
    this.$bus.$off("refreshForm");
    this.$bus.$off("addCarrier");
    this.$bus.$off("addPort");
  }

  @Watch("bill")
  watchBill() {
    this.newBill = this.bill;
    this.carrierQuery = this.getQuery(this.bill.carrierName, this.bill.carrier);
    this.dpQuery = this.getQuery(this.bill.dpname, this.bill.dp);
    this.apQuery = this.getQuery(this.bill.apname, this.bill.ap);
    this.fpQuery = this.getQuery(this.bill.fpname, this.bill.fp);
  }

  getQuery(str1: string, str2: string) {
    if (str1 && str2) {
      return `${str1} (${str2})`;
    }
    return "";
  }

  @Watch("isCreateBill")
  watchCreateBill() {
    if (this.isCreateBill) {
      this.newBill = new Bill();
      this.carrierQuery = "";
      this.dpQuery = "";
      this.apQuery = "";
      this.fpQuery = "";
    }
  }
  /**
   * CAll API get all carriers
   */
  getCarriers() {
    CommonService.getAllCarrier().then(res => {
      if (res.status === 200) {
        let arr: string[] = [];
        this.carrier = res.data.map((item: any) => {
          arr.push(`${item.name} (${item.code})`);
          return new IdNameCode(item);
        });
        this.carrierString = arr.join(" ");
      }
    });
  }

  /**
   * Call API get all ports
   */
  getPorts() {
    CommonService.getAllPort().then(res => {
      if (res.status === 200) {
        let arr: string[] = [];
        this.port = res.data.map((item: any) => {
          arr.push(`${item.name} (${item.code})`);
          return new IdNameCode(item);
        });
        this.portString = arr.join(" ");
      }
    });
  }

  fetchResults(from: EAutocomplate) {
    const filter = this.filterResults(from);
    switch (from) {
      case EAutocomplate.CARRIER:
        this.carrierSuggestions = [];
        filter.length && this.carrierSuggestions.push({ data: filter });
        break;
      case EAutocomplate.DP:
        this.dpSuggestions = [];
        filter.length && this.dpSuggestions.push({ data: filter });
        break;
      case EAutocomplate.AP:
        this.apSuggestions = [];
        filter.length && this.apSuggestions.push({ data: filter });
        break;
      case EAutocomplate.FP:
        this.fpSuggestions = [];
        filter.length && this.fpSuggestions.push({ data: filter });
        break;
    }
  }

  filterResults(from: EAutocomplate) {
    switch (from) {
      case EAutocomplate.CARRIER:
        return this.carrier
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.carrierQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      case EAutocomplate.DP:
        return this.port
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.dpQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      case EAutocomplate.AP:
        return this.port
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.apQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      case EAutocomplate.FP:
        return this.port
          .filter((item: any) => {
            if (
              [item["code"], item["name"]]
                .join("")
                .toLowerCase()
                .indexOf(this.fpQuery.toLowerCase()) > -1
            ) {
              return `${item["name"]} (${item["code"]})`;
            }
          })
          .sort();
      default:
        return [];
    }
  }

  renderSuggestion(suggestion: any) {
    return `${suggestion.item.name} (${suggestion.item.code})`;
  }

  getSuggestionValue(suggestion: any, from: EAutocomplate) {
    switch (from) {
      case EAutocomplate.CARRIER:
        this.newBill.carrier = suggestion.item.code;
        this.newBill.carrierName = suggestion.item.name;
        this.carrierQuery = `${suggestion.item.name} (${suggestion.item.code})`;
        break;
      case EAutocomplate.DP:
        this.newBill.dp = suggestion.item.code;
        this.newBill.dpname = suggestion.item.name;
        this.dpQuery = `${suggestion.item.name} (${suggestion.item.code})`;
        break;
      case EAutocomplate.AP:
        this.newBill.ap = suggestion.item.code;
        this.newBill.apname = suggestion.item.name;
        this.apQuery = `${suggestion.item.name} (${suggestion.item.code})`;
        break;
      case EAutocomplate.FP:
        this.newBill.fp = suggestion.item.code;
        this.newBill.fpname = suggestion.item.name;
        this.fpQuery = `${suggestion.item.name} (${suggestion.item.code})`;
        break;
    }
    return `${suggestion.item.name} (${suggestion.item.code})`;
  }

  triggerInputChange(from: EAutocomplate) {
    switch (from) {
      case EAutocomplate.CARRIER:
        (this.$refs.carrier as any).reset();
        (this.$refs.carrier as any).syncValue(this.newBill.carrier);
        break;
      case EAutocomplate.DP:
        (this.$refs.dp as any).reset();
        (this.$refs.dp as any).syncValue(this.newBill.dp);
        break;
      case EAutocomplate.AP:
        (this.$refs.ap as any).reset();
        (this.$refs.ap as any).syncValue(this.newBill.ap);
        break;
      case EAutocomplate.FP:
        (this.$refs.fp as any).reset();
        (this.$refs.fp as any).syncValue(this.newBill.fp);
        break;
    }
  }

  refreshClick() {
    this.$bus.$emit("refreshClick");
  }

  refresh() {
    this.newBill = new Bill();
    this.carrierQuery = "";
    this.apQuery = "";
    this.dpQuery = "";
    this.fpQuery = "";
    (this.$refs.formBill as any).reset();
    this.$emit("refreshForm");
  }

  async submitFormBill() {
    if (!this.newBill.id) {
      this.newBill.id = `LUCAS_${this.count}`;
      this.count++;
      this.$emit("submitBill", this.newBill);
      this.refresh();
    } else {
      this.$emit("submitBill", this.newBill);
      this.$bus.$emit("refreshClick");
    }
  }
}
export enum EAutocomplate {
  CARRIER,
  DP,
  AP,
  FP
}
