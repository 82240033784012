






























































































import { Component, Vue } from "vue-property-decorator";
import FormBill from "@/views/Contract/CompleteContract/FormBill.vue";
import ListBill from "@/views/Contract/CompleteContract/ListBill.vue";
import ContainerTab from "@/views/Contract/CompleteContract/ContainerTab.vue";
import FormCreateContract from "@/views/Contract/ModalCreate/FormCreateContract.vue";
import ContractService from "@/services/ContractService";
import { Bill, ContractStatus, NewContract } from "@/models";

@Component({
  components: {
    FormCreateContract,
    FormBill,
    ListBill,
    ContainerTab
  }
})
export default class CompleteContract extends Vue {
  private startScreen: boolean = true;

  private contractId: number = Number(this.$route.params.contractId);
  private currentContract: NewContract = new NewContract();
  private selectedBill: Bill = new Bill();
  private isCreateBill: boolean = true;
  private triggerUpdateBills: boolean = true;

  created() {
    this.getContractDetail();
    this.$bus.$on("confirmedSave", () => {
      this.confirmedSave();
    });
  }

  destroyed() {
    this.$bus.$off("confirmedSave");
  }

  /**
   * Call API to get contract detail
   */
  getContractDetail() {
    ContractService.getContractById(this.contractId)
      .then(res => {
        if (res.status === 200) {
          this.currentContract = res.data;
          // if (this.currentContract.status === ContractStatus.DONE) {
          //   if (this.$route.params.from) {
          //     this.$router.push(this.$route.params.from);
          //   } else {
          //     this.$router.push({ name: "dashboard" });
          //   }
          // }
        }
      })
      .finally(() => (this.startScreen = false));
  }

  openEditBasicInfo() {
    this.$bvModal.show("modal-create-contract");
  }

  formatTotal(sum: number) {
    return new Intl.NumberFormat("currency").format(sum);
  }

  rematchTotal() {
    let totalValue = 0;
    // let totalPrice = 0;

    const checkNaN = (val: number) => (isNaN(val) ? 0 : val);

    const consBill: Bill[] = (this.$refs.containerTab as any).consBills;

    consBill.forEach(bill => {
      bill.containers.forEach(cons => {
        cons.containerProducts.forEach(prod => {
          totalValue =
            checkNaN(totalValue) + checkNaN(prod.value) * checkNaN(prod.weight);
          // totalPrice =
          //   checkNaN(totalPrice) + checkNaN(prod.price) * checkNaN(prod.weight);
        });
      });
    });

    this.currentContract.totalValue = Number(checkNaN(totalValue).toFixed(3));
    // this.currentContract.totalPrice = Number(checkNaN(totalPrice).toFixed(3));
  }

  refreshForm() {
    this.isCreateBill = true;
    this.selectedBill = new Bill();
  }

  async submitBill(bill: Bill) {
    this.selectedBill = new Bill();
    if (this.isCreateBill) {
      this.currentContract.billList.push(bill);
      this.$bus.$emit("createBill", bill);
    } else {
      const index = this.currentContract.billList.findIndex(
        item => item.id === bill.id
      );
      this.currentContract.billList[index] = bill;
      this.$bus.$emit("updateBill", bill);
    }
    this.triggerUpdateBills = !this.triggerUpdateBills;
  }

  selectBill(bill: Bill) {
    this.selectedBill = bill;
    this.isCreateBill = false;
  }

  deleteBill(billId: number | string) {
    this.currentContract.billList = this.currentContract.billList.filter(
      bill => bill.id !== billId
    );
    this.$bus.$emit("deleteBill", billId);
    this.rematchTotal();
  }

  deleteSelectedBill() {
    this.isCreateBill = true;
    this.rematchTotal();
  }

  updateContractBasic(updateContract: NewContract) {
    const billList = this.currentContract.billList;
    Object.assign(this.currentContract, updateContract);
    this.currentContract.billList = billList;
  }

  async contractSubmit() {
    await (this.$refs.formBill as any).$refs.formBill.handleSubmit(
      (this.$refs.formBill as any).submitFormBill
    );
    const valid = (this.$refs.formBill as any).$refs.formBill.flags.valid;

    if (!valid) return;

    setTimeout(() => {
      if (this.selectedBill.id) {
        this.$bus.$emit("saveBill");
      } else {
        this.confirmedSave();
      }
    }, 100);
  }

  confirmedSave() {
    const consBill: Bill[] = (this.$refs.containerTab as any).consBills;
    const toString = (str: Date | null) => {
      const mm = this.$moment(str, "DD-MM-YYYY HH:mm:ss");
      if (mm.isValid()) {
        return mm.format("DD-MM-YYYY HH:mm:ss");
      }
      return "";
    };
    consBill.forEach(bill => {
      if (String(bill.id).includes("LUCAS")) bill.id = "";
      bill.contractStatus = this.currentContract.status;
      bill.billDate = toString(bill.billDate);
      bill.eta = toString(bill.eta);
      bill.etd = toString(bill.etd);
      bill.etf = toString(bill.etf);
      bill.vetDate = toString(bill.vetDate);
      bill.clearDate = toString(bill.clearDate);
    });
    this.currentContract.billList = consBill;
    this.currentContract.status === ContractStatus.NEW
      ? (this.currentContract.status = ContractStatus.PROCESSED)
      : "";
    this.currentContract.timeSigned = toString(this.currentContract.timeSigned);
    ContractService.createContract(this.currentContract)
      .then(res => {
        if (res.status === 200) {
          this.$swal({
            icon: "success",
            title: `Lưu thành công`,
            timer: 2000,
            toast: true,
            showConfirmButton: false,
            position: "bottom-end"
          });
          if (this.$route.params.from) {
            this.$router.push(this.$route.params.from);
          } else {
            this.$router.push({ name: "dashboard" });
          }
        }
      })
      .catch(() => {
        this.$swal({
          icon: "error",
          title: `Có lỗi xảy ra`,
          timer: 2000,
          toast: true,
          showConfirmButton: false,
          position: "bottom-end"
        });
      });
  }

  openModalDocument() {
    this.$bvModal.show("modal-document");
  }
}
